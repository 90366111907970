function Header() {
  return (
    <header>
      <h1>Siraj Paper Store</h1>
      <h2>Coming Soon</h2>

      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
