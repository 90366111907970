import React from 'react';
import Card from "./Card";

function Services() {
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    function importAll(r) {
      let images = {};
      r.keys().forEach((item) => { 
        images[item.replace('./', '')] = r(item); 
      });
      return images;
    }
    
    const images = importAll(require.context('../assets/products', false, /\.(png|jpe?g|svg)$/));
    
    // Convert the images object into an array of products
    const productsList = Object.entries(images).map(([fileName, imagePath]) => {
      const name = fileName.split('.')[0];
      const title = name
        .split(/[-_]/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      
      return {
        title,
        img: imagePath, // Use the full image path
      };
    });
    
    setProducts(productsList);
  }, []);

  return (
    <div className="container services">
      <h2 className="main-title text-center">SERVICES</h2>
      <div className="card-cover">
        <div className="col-md-12">
          <div className="row">
            {products.map((product, index) => (
              <div key={index} className="col-md-4 mb-2">
                <Card 
                  img={product.img}
                  text={product.title}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;